// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-engravidar-tsx": () => import("./../../../src/pages/engravidar.tsx" /* webpackChunkName: "component---src-pages-engravidar-tsx" */),
  "component---src-pages-fisioterapeuta-pelvica-tsx": () => import("./../../../src/pages/fisioterapeuta-pelvica.tsx" /* webpackChunkName: "component---src-pages-fisioterapeuta-pelvica-tsx" */),
  "component---src-pages-google-nest-tsx": () => import("./../../../src/pages/google-nest.tsx" /* webpackChunkName: "component---src-pages-google-nest-tsx" */),
  "component---src-pages-guia-de-reembolso-tsx": () => import("./../../../src/pages/guia-de-reembolso.tsx" /* webpackChunkName: "component---src-pages-guia-de-reembolso-tsx" */),
  "component---src-pages-health-tsx": () => import("./../../../src/pages/health.tsx" /* webpackChunkName: "component---src-pages-health-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mamae-bebe-tsx": () => import("./../../../src/pages/mamae-bebe.tsx" /* webpackChunkName: "component---src-pages-mamae-bebe-tsx" */),
  "component---src-pages-manual-1-otri-tsx": () => import("./../../../src/pages/manual-1otri.tsx" /* webpackChunkName: "component---src-pages-manual-1-otri-tsx" */),
  "component---src-pages-midia-tsx": () => import("./../../../src/pages/midia.tsx" /* webpackChunkName: "component---src-pages-midia-tsx" */),
  "component---src-pages-obstetras-porto-seguro-saude-tsx": () => import("./../../../src/pages/obstetras-porto-seguro-saude.tsx" /* webpackChunkName: "component---src-pages-obstetras-porto-seguro-saude-tsx" */),
  "component---src-pages-parto-normal-tsx": () => import("./../../../src/pages/parto-normal.tsx" /* webpackChunkName: "component---src-pages-parto-normal-tsx" */),
  "component---src-pages-planejando-gravidez-tsx": () => import("./../../../src/pages/planejando-gravidez.tsx" /* webpackChunkName: "component---src-pages-planejando-gravidez-tsx" */),
  "component---src-pages-plano-de-parto-tsx": () => import("./../../../src/pages/plano-de-parto.tsx" /* webpackChunkName: "component---src-pages-plano-de-parto-tsx" */),
  "component---src-pages-plataforma-tsx": () => import("./../../../src/pages/plataforma.tsx" /* webpackChunkName: "component---src-pages-plataforma-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-servicos-tsx": () => import("./../../../src/pages/servicos.tsx" /* webpackChunkName: "component---src-pages-servicos-tsx" */),
  "component---src-pages-simule-pacote-tsx": () => import("./../../../src/pages/simule-pacote.tsx" /* webpackChunkName: "component---src-pages-simule-pacote-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-theia-gravidez-tsx": () => import("./../../../src/pages/theia-gravidez.tsx" /* webpackChunkName: "component---src-pages-theia-gravidez-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

